// import { useContext } from "react";
// import LoginContext from "../login/LoginContext";
// import { useTranslation } from "react-i18next";
// import ReactPlayer from 'react-player'
import '../App.css'

function Epk() {
  const blah = () => window.open("https://linktr.ee/badkuzu", '_blank').focus();
  
  return <div>
    <button onClick={blah} type="submit" className="p-6" style={{ border: "1px solid white", color: "white", backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
      <text className="text-2xl font-bold">More links {'>>>'}</text> <br />
      <text>Streaming, Socials etc.</text>
    </button>
  </div>
}

export default Epk;
